import * as React from "react"
import Hero from "../views/components/heroShort/hero";
import Letter from "../views/components/common/letter";
import {Box, ChakraProvider, Container, Heading} from "@chakra-ui/react";
import Header from "../views/components/header/header";
import Fonts from "../views/theme/fonts";
import theme from "../views/theme/theme";
import Seo from "../views/components/head/seo";
import Footer from "../views/components/footer/footer";

const NotFoundPage = () => {
    const page = { title: 'Błąd 404'}
    const node = { contentTypeName : '404'}
    const HeaderBg = ('post' === node.contentTypeName) ? 'transparent' : '#005B2E'
    return (
        <>
            <Fonts/>
            <ChakraProvider theme={theme}>
                <Seo node={node}/>
                <Container maxW='100%' paddingX='0' bg={HeaderBg}>
                    <Header node={node} />
                </Container>

                <Container maxW='100%'>
                    <Hero page={page}/>
                    <Letter letter={'404'} right={'0'} top={'0'} color={'#b3b3b3'} opacity={'0.2'} zIndex={'0'}/>
                    <Box id={'p404'}
                         p={{ base: '5%', md:'120px 5% 40px 5%',xl:'50px 15% 50px 15%'}}
                         position={'relative'}
                         maxW={'1920px'}
                         m={'auto'}
                    >
                        <Heading as={'h2'}>Ups... strony nie znaleziono</Heading>
                        <Box mt={'50px'}>
                            <Box fontSize={'16px'} mb={'30px'}>
                                Szukasz wpisu z <Box as={'a'} href={'/blog'} fontWeight={'bold'} fontSize={'20px'} textDecoration={'underline'}>Bloga</Box> albo
                                <Box as={'a'} href={'/oferta/'} fontWeight={'bold'} fontSize={'20px'} textDecoration={'underline'}> Oferty</Box>?
                            </Box>
                            <Box>
                            Nie? W takim razie <Box as={'a'} href={'/kontakt/'} fontWeight={'bold'} fontSize={'20px'} textDecoration={'underline'}>Skontaktuj się z nami</Box> - napisz, czego szukasz!
                            </Box>
                        </Box>
                    </Box>
                </Container>

                <Container maxW='100%' paddingX='0'>
                    <Footer />
                </Container>

            </ChakraProvider>
        </>
    )
}

export default NotFoundPage
